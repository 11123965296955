<template>
  <div>
    <NavBar
      style="max-height: 50px;margin-bottom: 10px;"
      :username="currentUserName"
      :pageName="pageName"
    ></NavBar>

    <v-app>
      <div style="background-color: #f5faff; height: 93vh !important;">
        <v-row cols="12">
          <v-col cols="2">
            <SideBar style="max-height: 50px;" :pageName="pageName"></SideBar>
          </v-col>
          <v-col cols="10">
             <div style="margin: 10px 20px 20px 50px ">
             <div
              style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              vertical-align: middle;
              "
              >
            <div style = "width:30%" align="left">
            <span style="font-weight: 800;padding-left: 5px; font-size:12pt;text-transform: uppercase;letter-spacing: 1px;">
           <h3 >Create Admin</h3></span>
            </div></div>
  			     <!-- <h3 style="text-align:left; margin-left:65px; margin-top:30px;font-weight:800; font-size: 12pt; text-transform: uppercase;   letter-spacing: 1px;">Create Admin</h3> -->
  			     <div style="display: flex; flex-direction: row; margin: 20px -12px;width:90%;">
             <v-card style="width:75vw;margin: 16px ">
              <div style="display: flex; flex-direction: row; padding: 20px" align="left">
                <div style="width: 70%; display: flex; flex-direction: column" align="left" >
                  <div style="display: flex; flex-direction: row">
                    <div style="width: 100%; margin-top:12px;" align="left">User Full Name</div>
                    <div style="width: 100%" align="right">
                      <v-text-field label="Name" solo v-model="admin_name"
                      ></v-text-field>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div style="width: 100%;margin-top:12px;">User Email</div>
                    <div style="width: 100%">
                      <v-text-field label="Email" v-model="admin_email" solo ></v-text-field>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row" v-show = "false">
                    <div style="width: 100%;margin-top:12px;">User Type</div>
                    <div style="width: 100%">
                      <v-select
                        :items="admin_types_list"
                        v-model="admin_type"
                        label="Type"
                        solo
                      ></v-select>
                    </div>
                  </div>

                  <div align="right">
                    <v-btn @click="createAdmin" style="height: 44px" color="primary" :disabled="isCreateAdminClicked">
                      Create Admin
                    </v-btn>
                  </div>
                </div>
              </div>
  		      </v-card></div>
         </div> </v-col>
        </v-row>
      </div>
    </v-app>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../navbar.vue";
import SideBar from "../../../views/sideBar.vue";

export default {
  name: "CustomerHome",
  data() {
    return {
      admin_name: "",
      admin_type: "ADMIN",
      admin_types_list: ["ADMIN", "DOCTOR"],
      admin_email: "",
      created_by: "",
      currentUser: "",
      currentUserName: "",
      user_id: "",
      pageName: "",
      isCreateAdminClicked : false
    };
  },
  mounted() {
    this.pageName = "createAdmin";
    this.currentUser = this.$cookies.get("token");
    if (!this.currentUser) {
      this.$router.push({
        name: "AdminLogin",
      });
    } else {
      var getAccessData = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        adminType: "ADMIN",
      };
      axios
        .post(process.env.VUE_APP_BACKEND_URL + "/getAdmin", getAccessData)
        .then((getAdminResponse) => {
          this.currentUserName = getAdminResponse.data.data.admin_name;
          this.user_id = getAdminResponse.data.data._id;
        })
        .catch((getAdminError) => {
          if (getAdminError.response.status == 401) {
            window.alert("Sorry !! You are unauthorized !!");
            this.$router.push({
              name: "AdminLogin",
            });
          }
        });
    }
  },
  components: {
    NavBar,SideBar
  },
  methods: {
    gotoLoginPage() {
      var deleteData = {
        idToken: this.currentUser,
      };
      axios
        .post(process.env.VUE_APP_BACKEND_URL + "/deleteAdminToken", deleteData)
        .then((deleteResponse) => {
          this.$cookies.remove("token");
        })
        .catch((error) => {
        });
      localStorage.clear();
      this.$router.push({
        name: "AdminLogin",
      });
    },
    createAdmin() {
      this.isCreateAdminClicked = true
      var admin_name = this.admin_name;
      var admin_email = this.admin_email;
      var admin_type = this.admin_type;
      var created_by = this.user_id;
      var createAdminBody = {
        adminName: admin_name,
        adminType: admin_type,
        adminEmail: admin_email,
        createdBy: created_by,
        token: this.currentUser,
        typeOfUser: "ADMIN",
      };
      if (
        admin_name != "" &&
        admin_email != "" &&
        admin_type != "" &&
        created_by != ""
      ) {
        axios
          .post(
            process.env.VUE_APP_BACKEND_URL + "/createAdmin",
            createAdminBody
          )
          .then((createAdminResponse) => {
            alert("Admin created!!");
            this.isCreateAdminClicked = false
            this.$router.go();
          })
          .catch((createAdminError) => {
            this.isCreateAdminClicked  = false
            if (createAdminError.response.status == 401) {
              window.alert("Sorry !! You are unauthorized !!");
              this.$router.push({
                name: "AdminLogin",
              });
            }

            if (createAdminError.response.status == 305) {
              window.alert("Oops, User already exists");
            }
          });
      } else {
        alert("Please provide all the details");
        this.isCreateAdminClicked = false
      }
    },
  },
};
</script>

  <style>
td,
th {
  padding: 10px;
}
#facebook{
  display: none;
}
</style>